import aboutImg from '../assets/about.png'
import goalImg from '../assets/goal.png';
import rolesImg from '../assets/roles.png';
import visionImg from '../assets/vision.png';
import '../styles/about.css'
import { useTranslation } from "react-i18next";
import { Fade} from "react-awesome-reveal";
import { Link } from "react-router-dom";

export default function About() {

    const { t } = useTranslation(["About"]);

    return (
        <div className='about-section' id='about'>
            <h3 className='sub-heading'>{t("about-heading")}</h3>
            <div className="about">
                <div className="about-img">
                    <img src={aboutImg} alt="about us section" />
                </div>
                <div className="about-content">
                    <Fade direction='up'><p>{t("about-content")}</p></Fade>
                    <br />
                    <a href="#contact"><button className='secondary-btn'>{t("about-btn")}</button></a>
                </div>
            </div>
            <div className="intro-video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/6p9Il_j0zjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <br />
            <h3 className='sub-heading'>Our Objectives</h3>
            <div className="our-objectives">
                <div className="our-objectives-content">
                    <Fade direction='up'><p>At AIDA, our objective is to provide an immersive and comprehensive language learning experience that fosters linguistic proficiency and cultural insight. Through dynamic instruction, interactive activities, and personalized support, we aim to empower students of all ages and backgrounds to achieve fluency in Japanese. Our dedicated faculty and innovative curriculum are designed to inspire confident communication and a deep appreciation for the diverse cultures that surround these languages. Join us on a journey of language discovery, connection, and global understanding. Based on recent insights, Japan's economy has encountered minor setbacks. While a timeframe has not been established for these occurrences, they remain ongoing. It has also come to our attention that challenges have emerged within employment and other sectors. In response, we are dedicated to creating opportunities for exceptionally motivated and hardworking students. Despite prevailing challenges, we aim to empower these select individuals to actively contribute to Japan's educational and working landscape.</p></Fade>
                </div>
                <div className="our-objectives-img">
                    <img src={goalImg} alt="about us section" />
                </div>
            </div>
            <h3 className='sub-heading'>Our Roles</h3>
            <div className="our-roles">
                <div className="our-roles-img">
                    <img src={rolesImg} alt="about us section" />
                </div>
                <div className='our-roles-content'>
                    <Fade direction='up'><ul>
                        <li><b>Freedom of School Selection</b> Empowering students to choose a school from any region across Japan in alignment with their preferences.</li>
                        <li><b>Smooth Admission Process</b> Facilitating the necessary coordination and support for seamless admission into the chosen educational institution.</li>
                        <li><b>Enhanced Language Course</b> Delivering a six-month language course through our institutes, aimed at mitigating potential language barriers while in Japan.</li>
                        <li><b>Student Support</b> Providing qualified students with accommodations and engaging extracurricular activities to enrich their educational experience in Japan.</li>
                        <li><b>Financial Assistance</b> Extending essential financial assistance to eligible students based on our established partnership with a reputable financial entity in Japan, subject to their specific terms and conditions</li>
                    </ul></Fade>
                    <br />
                    <Fade direction='up'><p>We are pleased to inform you that after your submitted application has been thoroughly reviewed.We will decide to extend an invitation for an interview. Kindly note that to proceed further, certain requirements must be met.</p></Fade>
                </div>
            </div>
            <h3 className='sub-heading'>Our Vision & Mission</h3>
            <div className="our-vision-mission">
                <div className="our-vision-mission-content">
                    <Fade direction='up'><p> <h3>Bridging  the gap through Language </h3>At AIDA, we envision a harmonious convergence of cultures and professional prowess. Our vision is to create a nurturing environment that encourages perfect conduct and seamless integration for all students and workers engaged in activities within Japan. We are committed to instilling a profound appreciation for Japanese values while upholding the highest legal standards. Moreover, we are driven by the goal of elevating the prestige of our homeland while actively contributing to the expansion of employment horizons through our well-established worker placement initiatives. <br /> <h3>Empowering and Continuous Support</h3> Our mission is to guide individuals on a transformative journey during their extended stay in Japan. We are dedicated to providing unwavering support to both students and workers, ensuring their well-being and fostering enriching day-to-day experiences. Through collaborative partnerships with recipient organizations, our devoted team is committed to offering comprehensive assistance, thus enabling smooth cultural grasp and professional growth. By steadfastly upholding these principles, we not only ensure successful integration but also pave the way for enduring prosperity and advancement within the dynamic Japanese work environment.</p></Fade>
                </div>
                <div className="our-vision-mission-img">
                    <img src={visionImg} alt="about us section" />
                </div>
            </div>
            <div className="see-more-btn">
            <Link to='/aboutMore'><button className='secondary-btn'>See More about AIDA</button></Link>
            </div>
        </div>
    )
}