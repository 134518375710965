import Asnew from '../assets/Asnew.jpg';
import shin from '../assets/shin.png';
import head from '../assets/head.jpg';
import tokyo from '../assets/tokyo.png';
import matsu from '../assets/Matsudokokusai.png';
import bridge from '../assets/bridge.jpg';
import '../styles/partner.css';
import { Fade } from "react-awesome-reveal";

export default function Partner() {
    return(
        <div>
            <h3 className="sub-heading">Our Partners</h3>
          <Fade direction='top'><div className="partners">
                <div className="partner">
                    <img src={Asnew} alt="company logo" />
                    <p>Asnew Co.Ltd.</p>
                </div>
                <div className="partner">
                    <img src={shin} alt="company logo" id='shin'/>
                    <p>Shinjyuku International <br /> Exchange School</p>
                </div>
                <div className="partner">
                    <img src={head} alt="company logo" id='head' />
                    <p>Nagano International <br /> Culture College</p>
                </div>
                <div className="partner">
                    <img src={tokyo} alt="company logo" id='tokyo' />
                    <p>Tokyo World Japanese <br /> Language School</p>
                </div>
                <div className="partner">
                    <img src={matsu} alt="company logo" id='matsu' />
                    <p>Matsudo International School</p>
                </div>
                <div className="partner">
                    <img src={bridge} alt="company logo" id='bridge' />
                    <p>Tokyo Bridge Japanese <br /> Language School</p>
                </div>
            </div></Fade>
        </div>
    )
}