import '../styles/footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from "react-router-dom";
import logo from '../assets/aida.png';
import {HashLink} from 'react-router-hash-link';

export default function Footer() {
    return (
        <footer>
                <div className="footer-navlinks">
                    <div className="footer-logo">
                        <img src={logo} alt="logo" className='logo' />
                        <div className="social-links">
                            <li><FacebookIcon/></li>
                            <li><InstagramIcon/></li>
                            <li><LinkedInIcon/></li>
                        </div>
                    </div>
                    <ul>
                        <h3>Navigation</h3>
                        <li><a href="/#home">Home</a></li>
                        <li><a href="/#about">About Us</a></li>
                        <li><a href="/#discover">Discover</a></li>
                        <li><a href="/#courses">Courses</a></li>
                        <li><a href="/#contact">Contact Us</a></li>
                    </ul>
                    <ul>
                        <h3>More</h3>
                        <li><HashLink to='/academic#principal-msg'>Message from the principle</HashLink></li>
                        <li><HashLink to='/aboutMore#qa-section'>Quality assuarance of education</HashLink></li>
                        <li><HashLink to='/academic#staff'>Academic staff</HashLink></li>
                    </ul>
                    <ul>
                        <h3>Contact Us</h3>
                        <li>+94 11 264 2500</li>
                        <li>languageaida@gmail.com</li>
                        <li>114/3, Old Galle Road, Moratuwa, Sri Lanka</li>
                    </ul>
                </div>
            <hr />
            <small><a href="https://www.linkedin.com/in/dushansenadheera" target='__blank'>© 2023 Designed & Developed By Pixel Forge</a></small>
        </footer>
    )
}