import CountUp from 'react-countup';
import '../styles/header.css'
import { useTranslation } from "react-i18next";

export default function Header() {

    const { t } = useTranslation(["Header"]);

    return (
        <header id='home'>
            <div className="header-title">
                <h1 className='animate__animated animate__fadeInDown'>{t("header-title")}</h1>
                <h3 className='animate__animated animate__fadeInDown animate__delay-1s'>{t("header-subtitle")}</h3>
                <br />
                <div className="cta">
                    <a href="#about"><button className='cta-btn animate__animated animate__fadeInDown animate__delay-1s' >{t("header-btn")}</button></a>
                </div>
            </div>
            <div className="counters">
                <div className="counter">
                    <h1><CountUp end={10}/><span className='plus'>+</span></h1>
                    <p>{t("partner")}</p>
                </div>
                <div className="counter">
                    <h1><CountUp end={200} /> <span className='plus'>+</span></h1>
                    <p>{t("student")}</p>
                </div>
                <div className="counter">
                    <h1><CountUp end={10} /> <span className='plus'>+</span></h1>
                    <p>{t("course")}</p>
                </div>
            </div>
        </header>
    )
}