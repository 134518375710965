import n1 from '../assets/n1-tab.png';

const AdvanceCourses = [
    {
      id: 3,
      title: 'JLPT/NAT N1',
      description: 'JLPT/NAT N1 is the fifth level of the Japanese Language Proficiency Test. The ability to understand Japanese used in everyday situations to a certain degree.',
      level: 'Advance',
      image: n1
    }
  ]

  export default AdvanceCourses;