import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import Header from '../components/Header';
import About from '../components/About';
import ImgSlider from '../components/ImgSlider';
import Discover from '../components/Discover';
import Partner from '../components/Partner';
import Courses from '../components/Courses';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

export default function Landing() {
    return (
        <div>
            {/* <Navbar/>
            <MobileNav/> */}
            <Header />
            <br />
            <About />
            <br />
            <ImgSlider />
            <br />
            <Discover />
            <br />
            <Partner />
            <br />
            <Courses />
            <br />
            <Testimonials />
            <br />
            <Contact />
            <br />
            {/* <Footer /> */}
        </div>
    )
}