import '../styles/gallery.css';
import img1 from '../assets/img1.jpg';
import img2 from '../assets/img2.jpg';
import img3 from '../assets/img3.jpg';
import img4 from '../assets/img4.jpg';
import { Fade } from "react-awesome-reveal";

export default function GalleryPage() {
    return (
        <div className="gallery-page" id='qa-section'>
            <div className='about-more-QA'>
                <h3 className='sub-heading'>Quality assurance of education</h3>
                <br />
                <dl>
                    <div className="indivitual-attention">
                        <img src={img1} alt="" />
                        <Fade direction='up'><section>
                            <dt><b>INDIVIDUAL ATTENTION</b></dt>
                            <dd>We prioritize individualized attention and support for our students. Our experienced instructors are dedicated to understanding your unique learning style and adapting their teaching methods to ensure your progress and success. You can expect a warm and welcoming environment where you can freely engage with the language and receive personalized guidance.</dd>
                        </section></Fade>
                    </div>

                    <div className="suppotive-env">
                        <img src={img2} alt="" />
                        <Fade direction='up'><section>
                            <dt><b>SUPPORTIVE ENVIRONMENT</b></dt>
                            <dd>We prioritize creating a supportive and inclusive learning environment. Our institute fosters a sense of community, allowing you to connect with fellow students who share your passion for language learning. Through collaborative projects, group discussions, and interactive activities, you will have the opportunity to engage with peers and enhance your language skills together.</dd>
                        </section></Fade>
                    </div>

                    <div className="afford">
                        <img src={img3} alt="" />
                        <Fade direction='up'><section>
                            <dt><b>AFFORDABILITY</b></dt>
                            <dd>We believe that quality education should be accessible to all. As a small language institute, we are committed to providing affordable higher education options without compromising on the excellence of our programs. Our competitive tuition fees and flexible payment plans ensure that you can pursue your language learning goals without undue financial burden.</dd>
                        </section></Fade>
                    </div>

                    <div className="exam">
                        <img src={img4} alt="" />
                        <Fade direction='up'><section>
                            <dt><b>exam</b></dt>
                            <dd>Learning any language is not just about passing exams on paper. The student should be able to engage in daily conversations and be confident about their abilities. Going abroad often means you are on your own and need to make a decent living. In Japan you need to be able to comprehend and respond well in Japanese so that you can get a good part time or full time job. At our Japanese Language Institute, you can refine and enhance your speaking skills.</dd>
                        </section></Fade>
                    </div>
                </dl>
                <br />
                <p>We invite you to embark on a transformative educational journey with AIDA and unlock your full linguistic potential. We look forward to welcoming you to our Higher  Language Education Program and empowering you to achieve your language learning goals.</p>
            </div>
            <h3 className='sub-heading'>Gallery</h3>
            <div className="video">
                {/* <img src={qa1} alt="" />
                <img src={qa1} alt="" />
                <img src={qa1} alt="" />
                <img src={qa1} alt="" />
                <img src={qa1} alt="" />
                <img src={qa1} alt="" /> */}
                <iframe width="560" height="315" src="https://www.youtube.com/embed/wZ8BeQgbkbI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    )
}