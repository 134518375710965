import '../styles/testimonial.css';
import { createStyles, Card, Text, rem, Avatar, Blockquote } from '@mantine/core';
import testimonials from '../data/testimonials';
import { Fade } from "react-awesome-reveal";

const useStyles = createStyles(() => ({

  heading: {
    padding: rem('1rem'),
    display: 'flex',
    alignItems: 'center',
  },

  avater: {
    width: '32px',
    height: '32px',
  },

  content: {
    fontFamily: 'var(--primary-font)',
    color: 'var(--primary-font-color)',
    textAlign: 'justify',
    fontSize: '16px',
  }

}));

export default function Testimonial() {

  const { classes } = useStyles();

  return (
    testimonials.map((testimonial) => (
      <div className='testimonial-content'>
      <Fade direction='top'><Card shadow="sm" padding="lg" radius="md" withBorder className={classes.content} >
          <Card.Section className={classes.heading}>
            <Avatar src={testimonial.img} alt="it's me" radius='xl' sx={{ width: '3rem', height: '3rem' }} />
            <div className='info'>
              <h3>{testimonial.name}</h3>
              <h6>{testimonial.title}</h6>
            </div>
          </Card.Section>

          <Text size="sm" color="dimmed" className={classes.content}>
              {testimonial.description}
          </Text>

        </Card></Fade>
      </div>
    ))
  );
}