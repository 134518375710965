import chinthaka from '../assets/Mr.Chinthaka Perera - Program Manager.png';
import changa from '../assets/changa.png';
import bhagya from '../assets/bhagya.png';

const testimonials = [
    {
        id: 1,
        img: chinthaka,
        name: 'Chinthaka Perera',
        title: 'IT Engineer',
        description : 'I have successfully completed the Japanese language program at T. S. P. Management Services (Pvt) Ltd and earned an N4 certificate. Thanks to this certification and my higher education achievements in Sri Lanka, I have secured a rewarding employment opportunity in Japan. I extend my heartfelt gratitude to T. S. P. Management Services (Pvt) Ltd for their invaluable Japanese Language program, which has significantly contributed to my current success. I look forward to a prosperous future and continued excellence in their language classes.',
    },
    {
        id: 2,
        img: changa,
        name: 'Changa Himanshu',
        title: 'Tour Guide',
        description : 'I undertook a comprehensive Japanese language program at T. S. P. Management Services (Pvt) Ltd, successfully earning an N5 certificate. This language proficiency, acquired through T. S. P. Management not only enabled me to work in Japan but also guided me towards an enriching experience as a Tour Guide upon my return to Sri Lanka. I am immensely grateful for the Japanese Language Class process provided by T. S. P. Management Services (Pvt) Ltd, which has played an instrumental role in shaping my successful career path. I extend my sincere appreciation and well wishes for their continued success in the future.',
    },
    {
        id: 3,
        img: bhagya,
        name: 'Bhagya Jayawardana',
        title: 'Japanese language instructor',
        description : 'Foremost, I extend my heartfelt gratitude to Mr. Prashan Peiris and Mrs. Megumi Iwomoto, the esteemed principal of Matsudo International School, for serving as the pillars of success in my journey. My sincere appreciation extends to all the dedicated educators at Matsudo International School, as well as Mr. Prashan, whose unwavering support and guidance have significantly contributed to the refinement of my career skills and life as a whole. My acquaintance with Mr. Prashan dates back to 2011, and his ongoing counsel continues to illuminate my path of career development. I proudly assert that it is through the invaluable advice of Mr. Prashan that I have been able to swiftly achieve my goals. My heartfelt thanks for the profound impact you have had on my journey and for everything you have done to shape my success.'
    }
]

export default testimonials;