import { useState } from 'react';
import { TextInput, Textarea, Radio, Group, Select, NumberInput, Checkbox, Button } from '@mantine/core';
import { DateInput, YearPickerInput } from '@mantine/dates';
import Navbar from "../components/Navbar";
import MobileNav from "../components/MobileNav";
import Footer from "../components/Footer";
import { useForm, isNotEmpty, isInRange, hasLength } from '@mantine/form';
import { DatePickerInput  } from '@mantine/dates';
import '../styles/enroll.css'

export default function Register() {

    const [value, setValue] = useState(null);

    const form = useForm({
        validate: {
            fullName: isNotEmpty('Full Name cannot be empty'),
            address: isNotEmpty('Address cannot be empty'),
            telephone: hasLength({ min: 10, max: 10 }, 'Enter valid telephone number'),
            gender: isNotEmpty('Select your gender'),
            maritalStatus: isNotEmpty('Select your marital status'),
            DOB: isNotEmpty('Select your date of birth'), 
            nationality: isNotEmpty('Enter tour nationality'),
            age: isInRange({ min: 18, max: 30 }),
            school: isNotEmpty('Enter your school'),
            olYear: isNotEmpty('Select your GCE O/L year'),
            checkbox: isNotEmpty('Please agree to the terms and conditions'),
            sponserName: isNotEmpty('Enter your sponser name'),
            sponserAddress: isNotEmpty('Enter your sponser address'),
            sponserTelephone: hasLength({ min: 10, max: 10 }, 'Enter valid telephone number'),
            occupation: isNotEmpty('Enter your occupation'),
            relationship: isNotEmpty('Enter your relationship with the student'),
            purpose: isNotEmpty('Enter your purpose of study'),
            reason: isNotEmpty('Enter your reason for being His/Her sponser'),
        },
    });

    return (
        <div>
            {/* <Navbar />
            <MobileNav /> */}
            <div className='enroll-form-content' id='regForm'>
                <h3 id='application-admission'>Application for admission</h3>
                <br />
                <form className='enroll-form' onSubmit={form.onSubmit(() => { })}>
                    <TextInput
                        placeholder="Full Name"
                        label="Full Name"
                        withAsterisk
                        {...form.getInputProps('fullName')}
                    />
                    <br />
                    <Textarea
                        placeholder="Address"
                        label="Address"
                        withAsterisk
                        {...form.getInputProps('address')}
                    />
                    <br />
                    <TextInput
                        placeholder="Telephone"
                        label="Telephone"
                        withAsterisk
                        {...form.getInputProps('telephone')}
                    />
                    <br />
                    <Radio.Group
                        name="Gender"
                        label="Select your gender"
                        withAsterisk
                        {...form.getInputProps('gender')}
                    >
                        <Group mt="xs">
                            <Radio value="Male" label="Male" />
                            <Radio value="Female" label="Female" />
                        </Group>
                    </Radio.Group>
                    <br />
                    <Select
                        label="Marital status"
                        placeholder="Pick one"
                        data={[
                            { value: 'single', label: 'Single' },
                            { value: 'married', label: 'Married' },
                        ]}
                        {...form.getInputProps('maritalStatus')}
                    />
                    <br />
                    {/* <DateInput
                        value={value}
                        onChange={setValue}
                        label="Date of birth"
                        placeholder="Date of birth"
                        maw={400}
                        {...form.getInputProps('DOB')}
                    /> */}
                    <TextInput
                        placeholder="YYYY-MM-DD"
                        label="Date of birth"
                        withAsterisk
                        {...form.getInputProps('DOB')}
                    />
                    <br />
                    <TextInput
                        placeholder="Nationality"
                        label="Nationality"
                        withAsterisk
                        {...form.getInputProps('nationality')}
                    />
                    <br />
                    <NumberInput
                        placeholder="Your age"
                        label="Your age"
                        withAsterisk
                        min={18}
                        {...form.getInputProps('age')}
                    />
                    <br />
                    <TextInput
                        placeholder="School"
                        label="School"
                        withAsterisk
                        {...form.getInputProps('school')}
                    />
                    <br />
                    <h5>GCE Ordinary Level</h5>
                    <YearPickerInput
                        label="Select year"
                        placeholder="Select year"
                        value={value}
                        onChange={setValue}
                        mx="auto"
                        maw={400}
                        {...form.getInputProps('olYear')}
                    />
                    <div className="exams">
                        <table>
                            <tr>
                                <th></th>
                                <th>Subject</th>
                                <th>Grade</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                        value="Sinhala"
                                        disabled
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    <TextInput
                                        placeholder="subject"
                                        value="English"
                                        disabled
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                        value="Science"
                                        disabled
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                        value="Mathematics"
                                        disabled
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                        value="History"
                                        disabled
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                        value="Buddhism"
                                        disabled
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <h5>GCE Advanaced Level</h5>
                    <YearPickerInput
                        label="Select year"
                        placeholder="Select year"
                        value={value}
                        onChange={setValue}
                        mx="auto"
                        maw={400}
                    />
                    <div className="exams">
                        <table>
                            <tr>
                                <th></th>
                                <th>Subject</th>
                                <th>Grade</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>
                                    <TextInput
                                        placeholder="subject"
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    <TextInput
                                        placeholder="Subject"
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>
                                    <TextInput
                                        placeholder="subject"
                                        value="General English"
                                        disabled
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    <TextInput
                                        placeholder="subject"
                                        value="General Knowledge"
                                        disabled
                                    />
                                </td>
                                <td>
                                    <Select
                                        placeholder="Grade"
                                        data={[
                                            { value: 'A', label: 'A' },
                                            { value: 'B', label: 'B' },
                                            { value: 'C', label: 'C' },
                                            { value: 'S', label: 'S' },
                                            { value: 'F', label: 'F' },
                                        ]}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    <Textarea
                        placeholder="Work Experience"
                        label="Work Experience"
                    />
                    <br />
                    <Textarea
                        placeholder="Other Qualifications"
                        label="Other Qualifications"
                    />
                    <br />
                    <h3>Applicant & Sponser relationship</h3>
                    <br />
                    <TextInput
                        placeholder="Full Name"
                        label="Full Name"
                        withAsterisk
                        {...form.getInputProps('sponserName')}
                    />
                    <br />
                    <Textarea
                        placeholder="Address"
                        label="Address"
                        withAsterisk
                        {...form.getInputProps('sponserAddress')}
                    />
                    <br />
                    <TextInput
                        placeholder="Telephone"
                        label="Telephone"
                        withAsterisk
                        {...form.getInputProps('sponserTelephone')}
                    />
                    <br />
                    <TextInput
                        placeholder="Occupation"
                        label="Occupation"
                        withAsterisk
                        {...form.getInputProps('occupation')}
                    />
                    <br />
                    <Textarea
                        placeholder="Relationship with the Student"
                        label="Relationship with the Student"
                        {...form.getInputProps('relationship')}
                    />
                    <br />
                    <Textarea
                        placeholder="Reseaon for being His/Her Sponser"
                        label="Reseaon for being His/Her Sponser"
                        {...form.getInputProps('reason')}
                    />
                    <br />
                    <Textarea
                        placeholder="Purpose of study"
                        label="Purpose of study"
                        {...form.getInputProps('purpose')}
                    />
                    <br />
                    <Checkbox
                        label="I do hereby declare the above statement is true and correct."
                        {...form.getInputProps('checkbox')}
                    />
                    <br />
                    <Button type='submit' radius="xl">
                        Submit
                    </Button>
                </form>
            </div>
            {/* <Footer /> */}
        </div>
    )
}