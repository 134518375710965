import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { FaFax } from "react-icons/fa";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../styles/contact.css';
import { Fade } from "react-awesome-reveal";

export default function Contact() {
    return (
        <div id='contact'>
            <h3 className='sub-heading'>Get in touch</h3>
            <div className="contact-content">
                <iframe title="tsp management location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3144.5996689864296!2d79.88339925144781!3d6.776293648996646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae245067b03e0a1%3A0x2627ab8c98e31b60!2sTSP%20Management%20services!5e0!3m2!1sen!2slk!4v1689175791183!5m2!1sen!2slk" width="500" height="300" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
             <Fade direction="up"><ul>
                    <li> <BsFillTelephoneFill size={'1.5rem'} color={'var(--primary-color)'} className="icon"/> +94 11 264 2500</li>
                    <li> <FaFax size={'1.5rem'} color={'var(--primary-color)'} className="icon" />Fax +94 11 264 4742</li> 
                    <li> <MdEmail size={'1.75rem'} color={'var(--primary-color)'} className="icon" /> languageaida@gmail.com</li>
                    <li> <MdLocationPin size={'1.75rem'} color={'var(--primary-color)'} className="icon" />No. 114/3, Old Galle Road, Moratuwa, Sri Lanka</li>
                    <div className="social-links">
                    <a href="https://web.facebook.com/?_rdc=1&_rdr"><FacebookIcon /></a>
                    <a href="https://www.instagram.com/"><InstagramIcon /></a>
                    <a href="https://www.linkedin.com/authwall?trk=qf&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2F"><LinkedInIcon /></a>
                    </div>
                </ul></Fade>
            </div>
            <br />
            {/* <div className="enroll-content-section">
                <h3>Become a Partner</h3>
                <div className="enroll-section">
                    <h5>Are you interest to become a partner with us? <a href="">Become a Partner</a> now</h5>
                </div>
            </div> */}
        </div>
    )
}