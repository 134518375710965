import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from './pages/Register';
import Landing from './pages/Landing';
import BeginnerCourseDetails from './pages/BeginnerCourseDetials';
import IntermediateCourseDetails from './pages/IntermediateCourseDetails';
import AdvanceCourseDetails from './pages/AdvanceCourseDetails';
import Gallery from './pages/Gallery';
import 'animate.css';
import './App.css';
import { Suspense } from "react";
import Academic from "./pages/Academic";
import Navbar from "./components/Navbar";
import MobileNav from "./components/MobileNav";
import Footer from "./components/Footer";

function App() {

  return (
    <Suspense fallback={null}>
      <div className="App">
        <BrowserRouter>
        
          <Navbar />
          <MobileNav />
          <Routes>
            <Route path="/" element={<Landing />} />
          </Routes>
          <Routes>
            <Route path="/enroll" element={<Register />} />
            <Route path="/BeginnerCourse/:xyz" element={<BeginnerCourseDetails />} />
            <Route path="/IntermediateCourse/:xyz" element={<IntermediateCourseDetails />} />
            <Route path="/AdvanceCourse/:xyz" element={<AdvanceCourseDetails />} />
            <Route path="/academic" element={<Academic />} />
            <Route path="/aboutMore" element={<Gallery />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
