import Testimonial from "./Testimonial"
import '../styles/testimonial.css';


export default function Testimonials(){

    return(
        <div className='testimonials-content'>
            <h3 className='sub-heading'>Testimonials</h3>
            <div className='testimonials'>
            <Testimonial />
            </div>
        </div>
    )
}