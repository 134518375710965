import pradeep from '../assets/pradeep.png';
import prashan from '../assets/prashan.png';
import yasara from '../assets/yasara.png';
import bhagya from '../assets/bhagya.png';
import pasandi from '../assets/pasandi.png';

const academic = [
    {
        id: 1,
        name: 'Prashan Peiris',
        position: 'Managing Director, Principal',
        img: prashan,
    },
    {
        id: 2,
        name: 'Pradeep Cooray',
        position: 'Manager Operations',
        img: pradeep,
    },  
    {
        id: 3,
        name: 'Yesara Perera',
        position: 'Senior Executive Officer',
        img: yasara,
    },  
    {
        id: 3,
        name: 'Bhagya Jayawardana',
        position: 'International Lecture',
        img: bhagya,
    }, 
    {
        id: 4,
        name: 'Pasandi Peiris',
        position: 'Japan Coordinator',
        img: pasandi,
    }, 
]

export default academic;