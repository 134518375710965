import fuji from '../assets/fuji.jpg';
import shinjuku from '../assets/shinjuku.jpg';
import kyoto from '../assets/kyoto.jpg';
import city from '../assets/city.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../styles/imgSlider.css';

export default function ImgSlider() {
    return (
        <Carousel autoPlay={true} infiniteLoop={true} interval={3000} emulateTouch={true} showThumbs={false}>
            <div className='img-container'>
                <img src={fuji} alt='mount fuji' />
            </div>
            <div className='img-container'>
                <img src={shinjuku} alt='shinjuku city' />
            </div>
            <div className='img-container'>
                <img src={kyoto} alt='Kyoto city' />
            </div>
            {/* <div className='img-container'>
                <img src={city} alt='japan city' />
            </div> */}
        </Carousel>
    );
}