import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import '../styles/mobileNav.css'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import logo from '../assets/aida.png';
import {HashLink} from 'react-router-hash-link';

export default function MobileNav(){

    const [isClick, setClick] = useState("false");
    const dropAboutToggle = () => {
        setClick(!isClick);
    };
    
    const [isClick2, setClick2] = useState("false");
    const dropDiscoverToggle = () => {
        setClick2(!isClick2);
    };

    const [isClick3, setClick3] = useState("false");
    const toggleMenu = () => {
        setClick3(!isClick3);
    };

    const [isClick4, setClick4] = useState("false");

    const { i18n, t} = useTranslation(["About", "Header", "Navbar"]);

    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18next.changeLanguage("en");
        }
    }, []);

    const languageChangeJapanese = (e) => {
        i18n.changeLanguage("jp");
        setClick4(!isClick4);
    };

    const languageChangeEnglish = (e) => {
        i18n.changeLanguage("en");
        setClick4(!isClick4);
    };
    
    return(
        <nav className='mobile-nav'>
            <div className='navbar'>
            <HashLink to='/#home' ><img src={logo} alt="aida logo" className='aidalogo' /></HashLink>
                <div className='toggle-section'>
                {/* <button onClick={languageChangeJapanese} className={isClick4 ? "showJp" : "hideJp"}>日本語</button>
                <button onClick={languageChangeEnglish} className={isClick4 ? "hideEn" : "showEn"}>English</button> */}
                    <HiOutlineMenuAlt3 className='toggle-btn'onClick={toggleMenu}/>
                </div>
            </div>
            <ul className={isClick3 ? "hideMobileNav" : "showMobileNav animate__animated animate__fadeIn"}>
                <li onClick={toggleMenu}><HashLink to='/#home'>Home</HashLink></li>
                <div>
                <li><HashLink to="/#about" onClick={toggleMenu}>About Us</HashLink> <KeyboardArrowDownOutlinedIcon sx={{fontSize: 'medium'}} onClick={dropAboutToggle} /> </li>
                <div className='about-dropdown' onClick={dropAboutToggle}>
                    <ul className={isClick ? "dropdown" : "showDropdown"}>
                        <li onClick={toggleMenu}><HashLink to='/academic#principal-msg'>Message from the principle</HashLink></li>
                        {/* <li>The six values of AIDA</li> */}
                        <li onClick={toggleMenu}><HashLink to='/aboutMore#qa-section'>Quality assuarance of education</HashLink></li>
                        <li onClick={toggleMenu}><HashLink to='/academic#staff'>Administration staff</HashLink></li>
                    </ul>
                </div>
                </div>
                <div>
                <li onClick={toggleMenu}><HashLink to="#discover">Discover</HashLink></li>
                {/* <div className='discover-dropdown' onClick={dropDiscoverToggle}>
                    <ul className={isClick2 ? "dropdown" : "showDropdown"}>
                        <li>Higer education in Japan</li>
                        <li>Work in Japan</li>
                        <li>Conversation</li>
                        <li>Qualification</li>
                        <li>Cultural experience</li>
                    </ul>
                </div> */}
                </div>
                <li onClick={toggleMenu}><HashLink to="/#courses">Courses</HashLink></li>
                <li onClick={toggleMenu}><HashLink to="/#contact">Contact Us</HashLink></li>
                <div className="nav-cta">
                <a href="https://forms.gle/UTdPtobM71b476yr8"><button className='primary-btn'>Enroll Now</button></a>
            </div>
            </ul>
        </nav>
    )
}