import { Card, Image, Text, Badge, Button, Group, createStyles } from '@mantine/core';
import { Link } from "react-router-dom";
import AdvanceCourses from '../data/advanceCourse.js';

const useStyles = createStyles(() => ({

  card: {
    fontFamily: 'var(--primary-font)',
    fontSize: '16px',
  },

  // cardWidth: {
  //   width: '30%',
  // },

}));

export default function Course() {

  const { classes } = useStyles();

  return (
    AdvanceCourses.map((course) => (
      <Card className={classes.cardWidth} key={course.id} shadow="sm" padding="lg" radius="md" withBorder m='lg'>
        <Card.Section>
          <Image
            sx={{margin: 'auto'}}
            src= {course.image}
            alt="course image"
            height={200}
            fit="contain"
          />
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
          <Text className={classes.card} weight={700}>{course.title}</Text>
          <Badge className={classes.card} color="red" variant="light">
            Advance
          </Badge>
        </Group>

        <Text className={classes.card} size="sm" color="dimmed">
          {course.description}
        </Text>
        <Link to={`/AdvanceCourse/${course.id}`}>
        <Button className={classes.card} variant="light" color="blue" fullWidth mt="md" radius="md">
          Read More
        </Button>
        </Link>
      </Card>
    ))
  );
}