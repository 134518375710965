import n5 from '../assets/n5-tab.png';
import n4 from '../assets/n4-tab.png';

const beginnerCourses = [
    {
      id: 1,
      title: 'JLPT/NAT N5',
      description: "JLPT/NAT N5 is the first level of the Japanese Language Proficiency Test. The ability to understand some basic Japanese.",
      level: 'Beginner',
      image: n5
    },
    {
      id: 2,
      title: 'JLPT/NAT N4',
      description: 'JLPT/NAT N4 is the second level of the Japanese Language Proficiency Test. The ability to understand basic Japanese.',
      level: 'Beginner',
      image: n4
    },
  ]

  export default beginnerCourses;