import academic from '../data/academic.js';
import prashan from '../assets/prashan.png';
import '../styles/academic.css';

export default function Academic() {
    return (
        <div className='about-more'>
            <div className='about-more-content' id='principal-msg'>
                <h3 className='sub-heading'>Message from the principal</h3>
                <br />
                <div className="principal-img">
                    <img src={prashan} alt="Prashan-Peiris" />
                    <h4>Prashan Peiris</h4> 
                    <p>Managing Director, Principal</p>
                </div>
                <div className="principle-msg">
                    <p>
                        <div className="begin">
                            <p>
                                Dear students,
                            </p>
                        </div>
                     
                        As the Principal of this institute, it gives me great pleasure to welcome you to our website. At AIDA, we are committed to providing exceptional language education and creating an environment where students can thrive and achieve their language learning goals.
                        <br /><br />
                        Our institute has a history of more than three decades and our dedication to excellence is unparalleled. We believe in the power of language to connect people, bridge cultures, and open doors to new opportunities. Whether you are a beginner embarking on your language learning journey or an advanced learner seeking to refine your skills, we are here to support and guide you every step of the way.
                        We strongly believe that language learning is not just about grammar and vocabulary; it is about developing effective communication skills, cultural understanding, and a global mindset. Our experienced and passionate team of instructors is committed to providing engaging and interactive lessons that foster real-world language proficiency.
                        <br /><br />
                        We take pride in our small class sizes, which allow for personalized attention and meaningful student-teacher interactions. Our faculty members are not only experts in their respective languages but also caring mentors who are genuinely invested in the success and well-being of our students.
                        <br /><br />
                        As the Principal, I am dedicated to ensuring that our institute maintains a warm and inclusive learning environment where students feel valued and supported. We are committed to continuous improvement, and we welcome feedback from our students, parents, and the community to help us enhance our programs and services.
                        <br /><br />
                        I encourage you to explore our website to learn more about our language programs, and the unique opportunities that await you at AIDA. Whether your goals are academic, professional, or personal, we are here to help you achieve them.
                        Thank you for considering AIDA as your language education partner. I invite you to embark on this exciting journey with us, where language becomes a gateway to endless possibilities.
                        <br />
                        <div className="end">
                            <p>
                                Warm regards,
                                <br />
                                Prashan Peiris
                            </p>
                        </div>
                    </p>
                </div>
            </div>
            <h3 className='sub-heading' id='staff'>Administration staff</h3>
            <div className="academic-staff">
                {
                    academic.map((item) => {
                        return (
                            <div className="academic-staff-img">
                                <img src={item.img} alt="staff" />
                                <h3>{item.name}</h3>
                                <p>{item.position}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}