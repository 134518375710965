import { Accordion, createStyles, rem } from '@mantine/core';
import { Fade } from "react-awesome-reveal";

const useStyles = createStyles(() => ({

    wrapper: {
        padding: rem('4.5rem 4rem'),
    },

    heading: {
        fontFamily: 'var(--primary-font)',
        fontWeight: 700,
        color: 'var(--primary-color)',
    },

    content: {
        fontFamily: 'var(--primary-font)',
        textAlign: 'justify',
        fontSize: '16px',
    },

    '@media only screen (max-width: 768px)': {
        accordion: {
            padding: rem('1rem 2rem'),
        },
    },

}));

export default function Discover() {

    const { classes } = useStyles();

    return (
        <div className={classes.wrapper} id='discover'>
            <h3 className='sub-heading'>Discover</h3>
            <br />
            <Accordion className={classes.accordion} variant="separated" multiple={true} defaultValue={["Higher education in Japan", "Work in Japan", "Conversation", "Qualification", "Cultural experience"]}>
                <Fade direction='up'><Accordion.Item value="Higher education in Japan">
                    <Accordion.Control className={classes.heading}>Higher language education at AIDA</Accordion.Control>
                    <Accordion.Panel className={classes.content}>
                        At our institute, we firmly believe that education holds the key to boundless opportunities. Our unwavering commitment is to deliver unparalleled language education of the highest caliber. Introducing our Higher Education Language Program, meticulously crafted for those who harbor a deep passion for linguistic proficiency and aspire to elevate their skills. Whether you envision a flourishing professional life, pursue academic excellence, or simply seek personal enrichment, our program offers a tailored and immersive learning journey to meet your unique objectives.
                        With a strategic focus on excellence, our curriculum serves as a solid groundwork, equipping students to seamlessly navigate Japanese academic institutions such as language schools, vocational schools, and universities. Your language journey with us is not just a course; it's a transformative experience to shape your future endeavors.
                    </Accordion.Panel>
                </Accordion.Item></Fade>
                <br />
                <Fade direction='up'><Accordion.Item value="Work in Japan">
                    <Accordion.Control className={classes.heading}>Work in Japan</Accordion.Control>
                    <Accordion.Panel className={classes.content}>As a student within our esteemed institution, you gain privileged access to tailor-made employment prospects in Japan, meticulously curated in collaboration with renowned recruitment entities. Our dedication lies in connecting your aspirations with the perfect professional pathways, ensuring an experience that aligns with your unique needs.We believe that guiding our students during their first few months in Japan will enable them to find new opportunities and unlock their passion for working. Moreover, if you are someone skilled in IT then we have a whole lot more in store for you! Do join us to explore the endless opportunities that await you!</Accordion.Panel>
                </Accordion.Item></Fade>
                <br />
                <Fade direction='up'><Accordion.Item value="Conversation">
                    <Accordion.Control className={classes.heading}>Conversation</Accordion.Control>
                    <Accordion.Panel className={classes.content}>For students aspiring to cultivate proficient Japanese language conversation skills, AIDA is here to provide a comprehensive learning experience. Our accredited courses, recognized by authorities in Sri Lanka, cater to both educational and employment pursuits. Our highly skilled and qualified instructors lead engaging sessions within our institute, supplemented by dynamic online channels in collaboration with Japanese organizations. From foundational knowledge to advanced fluency, we are dedicated to empowering our students on their linguistic journey.</Accordion.Panel>
                </Accordion.Item></Fade>
            </Accordion>
        </div>
    )
} 