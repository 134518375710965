import BeginnerCourses from './BeginnerCourse.jsx';
import IntermediateCourses from './IntermediateCourse.jsx';
import AdvanceCourses from './AdvanceCourse.jsx';
import { Tabs, createStyles } from '@mantine/core';
import '../styles/courses.css';

const useStyles = createStyles(() => ({

  card: {
    fontFamily: 'var(--primary-font)',
    border : '1px solid lightgray',
    fontSize: '14px',
  },

}));

export default function Courses() {

  const { classes } = useStyles();

  return (
    <div className='course-content' id='courses'>
      <h3 className='sub-heading'>Courses</h3>
      <br />
      <Tabs variant="pills" radius="xl" defaultValue="Beginner">
        <Tabs.List sx={{display:'flex'}}>
          <Tabs.Tab className={classes.card} value="All">All</Tabs.Tab>
          <Tabs.Tab className={classes.card} value="Beginner">Beginner</Tabs.Tab>
          <Tabs.Tab className={classes.card} value="Intermediate">Intermediate</Tabs.Tab>
          <Tabs.Tab className={classes.card} value="Advance">Advance</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="All" pt="xs">
          <div className='courses'>
          <BeginnerCourses />
          </div>
          <div className='courses'>
          <IntermediateCourses />
          </div>
          <div className='courses'>
          <AdvanceCourses />
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="Beginner" pt="xs">
          <div className='courses'>
            <BeginnerCourses />
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="Intermediate" pt="xs">
          <div className='courses'>
            <IntermediateCourses />
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="Advance" pt="xs">
          <div className='courses'>
            <AdvanceCourses />
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}