import { useParams } from 'react-router-dom';
import AdvanceCourse from '../data/advanceCourse.js';
import Navbar from '../components/Navbar.jsx';
import Footer from '../components/Footer.jsx'
import '../styles/courseDetails.css'

export default function IntermediateCourseDetails() {

    const { xyz } = useParams();
    const course = AdvanceCourse.find((course) => course.id == xyz);

    if (course) {
        return (
            <div>
                <div className="course-details">
                    <h1>{course.title}</h1>
                    <div className="course-img-container">
                        <img src={course.image} alt={course.title} />
                    </div>
                    <br />
                    <p>{course.description}</p>
                    <br />
                    <p>
                        Weekend Classes: Saturday and Sunday From 9.00 a.m. to 5.00 p.m.
                        <br />
                        This time table should be changed by the teacher and attendance should be taken by the teacher and filed.
                    </p>
                    <br />
                    <h5>Sitting For Exams</h5>
                    <p>  When students are ready to take NAT/JLPT exams, the teacher will inform the Director of the institute and parents and arrange exams Dates. Should check exams fees and inform parents. </p>
                    <br />
                    <h5>Two Centers</h5>
                    <ul>
                        <li>Sasakawa center</li>
                        <li>J.R. Jayawadane Foundation Center</li>
                    </ul>
                    <br />
                    <h5>Graduation</h5>
                    <p> At the time to Graduation (after completing the course) a certificate will be given by the Institute. This certificate will help the student to continue Higher Studies in another institution and not for visa purpose.</p>
                </div>
            </div>
        )
    }
    else {
        return (
            <div>
                <h1>Sorry, {course.title} not found</h1>
            </div>
        )
    }

}