import n3 from '../assets/n3-tab.png';
import n2 from '../assets/n2-tab.png';

const IntermediateCourses = [
    {
      id: 1,
      title: 'JLPT/NAT N3',
      description: "JLPT/NAT N3 is the third level of the Japanese Language Proficiency Test. The ability to understand Japanese used in everyday situations to a certain degree.",
      level: 'Beginner',
      image: n3
    },
    {
      id: 2,
      title: 'JLPT/NAT N2',
      description: 'JLPT/NAT N2 is the fourth level of the Japanese Language Proficiency Test. The ability to understand Japanese used in everyday situations to a certain degree.',
      level: 'Intermediate',
      image: n2
    },
  ]

  export default IntermediateCourses;